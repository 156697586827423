/*
 *
 *   GULL - React Bootstrap Admin Template
 *
 *
*/

//@import "../iconsmind/iconsmind.css";
@import "variables";

@import "./bootstrap/mixins/grid";
@import "./phoneInput.css";
@import "theme.scss";
@import "./bootstrap/bootstrap.scss";
@import "./bootstrap/bootstrap-rtl.scss";
@import "./iconsmind/iconsmind.css";
@import "~bootstrap/scss/bootstrap";
@import "globals/globals";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$svgData: '%3csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23343a40" className="w-100 h-100"%3e%3cpath stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /%3e%3c/svg%3e';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

@media (min-width: 2000px) {
  .container-xxl {
    max-width: 1140px;
  }
}

.bg-primary-light {
  background-color: #9b7db8;
}

.bg-color-1 {
  background: white;
  background: linear-gradient(
    25deg,
    #d7d7d7 0%,
    #c8c8c8 28%,
    white 62%,
    white 100%
  );
}

.icon-hover * {
  transition: all 0.3s ease;
}

.icon-hover:hover img {
  transform: scale(1.05);
}

.address-input .form-control::placeholder {
  color: rgb(138, 138, 138);
}

.text-underline {
  text-decoration: underline;
}

.blog-bg {
  background-size: cover;
  background-position: center;
  height: 400px;
  @media (max-width: 800px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 170px;
  }
}

.blog-box {
  margin-top: -265px;
  @media (max-width: 800px) {
    margin-top: -180px;
  }

  @media (max-width: 500px) {
    margin-top: -50px;
  }
}

.blog-bg-color {
  background-color: #ffffff4f;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border: 0;
  span {
    font-size: 20px;
    color: #d5d1d1;
  }
  &:hover,
  &:focus,
  .active {
    background-color: unset;
    box-shadow: unset !important;

    span {
      color: #663399;
    }
  }
}

.page-link {
  border-radius: 50px;
}

.faq-tabs {
  .tab-content {
    background: white;
    border: 1px solid;
    border-color: #dee2e6 #dee2e6 !important;
    padding: 0px;
  }
  .faq-accordion {
    padding: 0px 1rem;
  }
  .nav-tabs {
    border-bottom: 0;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-link,
    .nav-link:hover {
      border: unset;
    }

    .nav-link.active {
      color: #663399;
      background-color: unset;
      border: unset;
      font-weight: 600;
    }
  }
}

.container-VMZ {
  h3 {
    display: none;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 14:1:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 13:58:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.fade-in-left {
  -webkit-animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-24 13:55:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.slide-in-elliptic-left-fwd {
  -webkit-animation: slide-in-elliptic-left-fwd 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: slide-in-elliptic-left-fwd 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    1s both;
}

/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1;
  }
}

.p-home {
  padding-top: 75px;
}

.p-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.bg-container-2 {
  background-color: transparent;
  background-position: bottom;
  background-size: cover;

  .full-width {
    width: 100%;
  }

  .fillter {
    backdrop-filter: blur(2.5px);
    padding-bottom: 140px;
  }

  .main-content-wrap {
    position: relative;
    z-index: 3;
    background-color: transparent;

    .form-control {
      background-position: 2% center !important;
    }
  }
}

.sm-menu {
  position: absolute;
  width: 265px;
  background-color: #f2f1f1 !important;
  border-radius: 6px;
  box-shadow: 0 0 5px 0px #9828b4;
  padding: 20px;
  top: 75%;
  right: 0;

  > div {
    display: flex;
    flex-direction: column;
  }
}

.steps-home {
  .pac-target-input {
    background-position: 3% center;
  }
}

.modal-pos {
  left: 1rem !important;
  top: 2.5rem !important;
  @media screen and (max-width: 992px) {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    position: absolute !important;
    width: 70% !important;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.breadcrumb ul {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px !important;
  padding-bottom: 8px !important;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.pos-3 {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  min-height: 100vh;
  min-width: 100vw;
}

.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold.active,
.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold:hover {
  color: #663399;
}

.layout-sidebar-large
  .sidebar-left
  .navigation-left
  .nav-item
  .nav-item-hold.active
  .nav-text {
  font-weight: 800;
}

.banner-card {
  top: 0.3rem;
  right: 0.4rem;
  z-index: 1660;
  padding-right: 2.5rem;

  @media screen and (max-width: 1300px) {
    right: 0.1rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 1095px) {
    top: unset;
    right: 0.4rem;
    bottom: 6rem;
  }
}

// sidebar-3

.sidebar-3 {
  position: fixed;
  top: 0;
  z-index: 1030;
  width: 100%;
  height: 100%;
  display: flex;

  &.show {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }

  &.hide {
    transform: translateX(-100%);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }

  &-menu {
    background-color: #fff;
    padding: 0.6rem;
    width: 70%;
  }

  &-overlay {
    flex: 1;
    background-color: #81818157;
  }
}

.main-content-wrap {
  min-height: auto !important;
  padding-bottom: 1.5rem !important;
}

// ===

.max-width-274 {
  max-width: 17.125rem;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.object-contain {
  object-fit: contain;
}

.bg-white-10 {
  background-color: #ffffff1a !important;
}

.bg-violet-2 {
  background-color: #5f3994;
}

.bg-violet-o-5 {
  background-color: #5f39940a;
}

.text-violet-2 {
  color: #5f3994;
}

.bg-orange {
  background-color: #f09d51 !important;
}

.text-orange {
  color: #f09d51;
}

.text-dark-gray {
  color: #939393;
}

.text-black-2 {
  color: #25252d;
}

.text-black-60 {
  color: #25252d9c;
}

.box-shadow-2 {
  box-shadow: 0px 4px 25px 0px #00000014;
}

.box-shadow-3 {
  box-shadow: 0px 8px 25px 0px #5f39940d;
}

.rounded-2 {
  border-radius: 0.625rem !important;
}

.rounded-t-2 {
  border-radius: 0.625rem 0.625rem 0 0 !important;
}

.rounded-xl {
  border-radius: 1.25rem !important;
}

.box-image {
  min-height: 21.875rem;
}

.box-cover-image {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.hero-detail-box {
  position: absolute;
  z-index: 2;
  right: -51px;
  top: 50%;
  transform: translateY(-50%);
}

.badge-box {
  display: inline-block;
  background-color: #5f39940f;
  color: #5f3994;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 4px 10px 4px 10px;
  border-radius: 6px;
}

.steps-form {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #8792a2;
    background-color: #dddce1;
    border-color: #dddce1;

    &:hover {
      box-shadow: none;
    }
  }
}

.step-box-1 {
  background-color: #fff;
  border-radius: 1.25rem;
  height: 13.75rem;
  border: 2px solid transparent;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover,
  &.active {
    border-color: #5f3994;

    h6 {
      color: #5f3994;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.step-image-box {
  height: 18.75rem;
  width: 18.75rem;
}

.step-image-box-2 {
  height: 23.3125rem;
  width: 23.3125rem;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.9695rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.5;
  outline: initial !important;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  color: #47404f;
  background-image: url("data:image/svg+xml,#{$svgData}");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.box-result-prices {
  margin: 0;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.125rem;

  strong {
    display: block;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2rem;
  }
}

.box-result-prices:nth-child(1) {
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 8px 0 0 8px;
}

.box-result-prices:nth-child(2) {
  border: none;
  border-bottom: 1px solid #5f3994;
  border-top: 1px solid #5f3994;
  background-color: #5f3994;
  color: #fff;
}

.box-result-prices:nth-child(3) {
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 8px 8px 0;
}

// notification-card

main {
  overflow: hidden;
}

.notification-pos {
  position: absolute;
  right: 7rem;
  top: 6rem;
  z-index: 40;

  @media screen and (max-width: 1385px) {
    right: 2rem;
  }
}

.notification-card {
  max-width: 17.6875rem;
  border-radius: 0.625rem;
  background-color: #fff;
  /* ombre */
  box-shadow: 0px 4px 25px 0px #00000014;

  &__avatar {
    min-width: 4.125rem;
    width: 4.125rem;
    height: 4.125rem;
  }

  &__badge {
    display: inline-block;
    padding: 0.375rem 0.625rem;
    border-radius: 0.5rem;
    background-color: #5f39940f;
    color: #5f3994;
    font-size: 0.75rem;
    font-weight: 600;
  }
  &__text {
    color: #25252d;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.phone-input .PhoneInputInput {
  border: 0;
}
.phone-input .PhoneInputInput:focus {
  border: 0;
  outline: 0;
}

.bg_input {
  background-color: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  box-shadow: unset;
  border-color: #c3c3c3;
  border-radius: 0;
  padding: 10px;
  padding-left: 0;
  height: 40px;
  font-size: 14px;
  border-style: solid;
}
.bg_input:focus {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-color: var(--main-color) !important;
}

.min-height-80vh {
  min-height: 80vh !important;
}

.min-height-60vh {
  min-height: 60vh !important;
}

@media (min-width: 576px) {
  .modal-sign {
    max-width: 430px !important;
  }
}

.evaluation_hero_header {
  margin-top: 50px;
}

.autocomplete_predictions {
  position: absolute;
  width: 69%;
  top: 65px;
  left: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-content: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    width: 90%;
  }
}

.autocomplete_prediction {
  background-color: white;
  background-image: url("./assets/icons/location.png");
  background-position: 5px;
  background-size: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 2px 2px;
  padding-left: 30px;
  border-bottom: 1px solid #ccc;
  z-index: 10;
  text-align: left;
}

// .autocomplete_prediction:hover {
//   background-color: #eee;
// }

.autocomplete_prediction_hovered {
  background-color: #eee;
}

// new styles

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 0.7rem;
}

.bg-violet-light {
  background-color: #5f39941a;
}

.bg-gray-light {
  background-color: #f5f6f780;
}

.badge-box-2 {
  padding: 0.25rem 0.625rem;
  border-radius: 0.375rem;
  background-color: #acc12f1a;
  color: #66731c;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0044rem;
}

.badge-box-3 {
  padding: 0.4399rem 0.7rem;
  border-radius: 0.4568rem;
  background-color: #8792a2;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.8rem;
  font-weight: 500;
}

.map-box {
  max-width: 62rem;
  height: 20rem;
  border-radius: 0.5rem;
}

.box-5 {
  border-radius: 1.3704rem;
  border: 0.0625rem solid #f5f6f7;
  background-color: #fcfbfd;
}

.text-muted-light {
  color: #8792a2;
}

.icon-box-1 {
  height: 3.75rem;
  width: 3.75rem;
  min-width: 3.75rem;
}

.icon-box-2 {
  height: 4.125rem;
  width: 4.125rem;
  min-width: 4.125rem;
}

.max-w-1 {
  max-width: 11.75rem;
}

.b-logo-2 {
  width: 9.375rem;
  height: 2.875rem;
}

.b-logo-2 img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.right-box-2 {
  max-height: 42vmax;

  @media screen and (max-width: 991px) {
    max-height: 100%;
  }
}

.blog-item {
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #ffffffe3;
}

//ffffffb3

.heading-big {
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
  font-size: 3.375rem;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 2.2rem;
  }
}

.heading-2 {
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.9375rem; /* 118.09% */

  @media screen and (max-width: 600px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

.heading-3 {
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
  font-size: 2.0663rem;
  font-weight: 600;
  line-height: 2.4401rem;

  @media screen and (max-width: 53.125rem) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
}

.sub-title {
  font-size: 1.2188rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1819rem;
}

.new-link {
  font-family: "Poppins", sans-serif;
  font-size: 1.246rem;
  font-weight: 500;
  line-height: normal;
}

.whoarewe-section p,
.services-section p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #8792a2;
}

.max-w-2 {
  max-width: 51.3125rem;
}

.max-w-3 {
  max-width: 25rem;
}

.max-w-4 {
  max-width: 30rem;
}

.card-3 {
  min-height: 6.25rem;
  border-radius: 3.125rem;
  background: #fff;
  box-shadow: 10px 30px 50px 0px #0000000d;
}

.card-3 .avatar {
  height: 2.375rem;
  width: 2.375rem;
  min-width: 2.375rem;
  border: 0.1875rem solid #fff;
  margin-left: -0.625rem;
}

.card-3 .avatar:nth-child(1) {
  margin-left: unset;
}

.card-3 .avatar img {
  object-fit: cover;
}

.card-3 .plus-avatar {
  margin-left: -0.625rem;
  height: 2.375rem;
  width: 2.375rem;
  min-width: 2.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1875rem solid #fff;
  background-color: #000;
  color: #fff;

  font-size: 1.25rem;
}

.card-3 .box-size {
  height: 3.75rem;
  width: 3.75rem;
  min-width: 3.75rem;
}

.card-3 .title {
  color: #2b2b2b;
  font-size: 1.2125rem;
  font-weight: 500;
}

.card-4 {
  border-radius: 1.875rem;
  background-color: #fff;
  box-shadow: 4px 10px 30px 0px #0000000f;
}

.team-card {
  .image-box {
    -webkit-mask-image: url("./assets/images/homepage/team-cover.svg");
    mask-image: url("./assets/images/homepage/team-cover.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    width: 12.5rem;
    height: 17.5rem;

    img {
      object-fit: cover;
    }
  }

  .name {
    color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    font-size: 1.725rem;
    font-weight: 600;
    line-height: normal;
  }
}

.estimates-card {
  border: 0.0779rem solid #f5f6f7;
  background-color: #fcfbfd;
  border-radius: 1.5575rem;

  &:hover,
  &.active {
    border-color: #5f3994;
    box-shadow: 0px 14.121px 41.533px 0px #8f90a652;
  }

  .title {
    color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    font-size: 1.4536rem;
    font-weight: 600;
    line-height: normal;
    width: 16.25rem;
  }

  .sub-text {
    color: #8792a2;
    font-family: "Poppins", sans-serif;
    font-size: 0.9864rem;
    font-weight: 500;
  }

  .estimates-badge {
    border-radius: 0.5192rem;
    background-color: #8792a2;

    color: #fcfbfd;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 0.9864rem;
    font-weight: 500;
  }
}

.services-section {
  .icon-box {
    height: 5.625rem;
    width: 5.625rem;
    min-width: 5.625rem;
    background-color: #5f3994;
    box-shadow: 4px 10px 30px #5f39944d;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    font-size: 1.2125rem;
    font-weight: 500;
  }
}

.getfirst-estimate-section {
  .box {
    border-radius: 3.125rem;
    background: linear-gradient(180deg, #c3dfed 0%, #dff0f7 100%);

    .text {
      max-width: 33rem;
      color: #8792a2;
      font-family: "Poppins", sans-serif;
      font-size: 1.375rem;
      font-weight: 400;
      line-height: 2.25rem;
    }

    .text-2 {
      max-width: 38rem;
    }

    .image-box {
      transform: translateY(1.625rem);
      max-width: 41.6875rem;
      max-height: 19.7966rem;
    }
  }
}

.blog-section {
  .new-blog-card {
    .image-box {
      height: 15rem;
      border-radius: 1.875rem;
      box-shadow: 10px 10px 30px 0px #00000033;
      overflow: hidden;

      img {
        border-radius: 1.875rem;
        object-fit: cover;
        transition: all 0.5s ease;
      }
    }

    h3 {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 1.725rem;
      font-weight: 500;
      line-height: normal;
    }

    p {
      color: #d4d4d4;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.625rem;
    }

    .nav-button {
      display: block;
      transition: all 0.5s ease;
    }

    &:hover {
      .image-box {
        img {
          transition: all 0.5s ease;
          transform: scale(1.1);
        }
      }

      .nav-button {
        transition: all 0.5s ease;
        transform: translateX(-1rem);
      }
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    gap: 2rem;

    @media screen and (max-width: 600px) {
      gap: 0.6rem;
    }
  }

  .slick-dots li button:before {
    font-size: unset;
    line-height: unset;
    opacity: 0.5;
    background-color: #fff;
  }

  .slick-dots li,
  .slick-dots li button,
  .slick-dots li button:before {
    height: 0.25rem;
    width: 5rem;
    padding: unset;

    @media screen and (max-width: 600px) {
      width: 2rem;
    }
  }
}

.card-5 {
  .image-box {
    height: 15rem;
    border-radius: 1.875rem;
  }

  .number-box {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100%;
    background-color: #fff;
    color: #5f3994;
    border: 0.25rem solid #5f3994;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.card-6 {
  border-radius: 1.875rem;
  background-blend-mode: overlay;
  background-color: #000000ae;
  background-size: cover;
  background-position: center;

  .image-box {
    height: 15rem;
    border-radius: 1.875rem;
  }
}

.new-footer {
  p {
    color: #8792a2;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.9375rem;
  }

  h5 {
    color: #2b2b2b;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2.25rem;
  }

  &-sections {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      min-width: 660px !important;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  &-ziaway-icon {
    width: 135px !important;
    @media screen and (max-width: 1000px) {
      width: 100% !important;
      display: flex;
      justify-content: center;
    }
  }

  .footer-list {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1.2rem;

    a {
      color: #2b2b2b;
      font-family: "Poppins", sans-serif;
      font-size: 0.875rem;

      &.active,
      &:hover {
        color: #5f3994;
      }
    }
  }
}

.bg-cover-image {
  background-size: cover;
  background-position: bottom;
  background-color: #ffffff9e;
  background-blend-mode: overlay;
}

.object-cover {
  object-fit: cover;
}

.row > .px-3 {
  width: inherit !important;
  max-width: fit-content !important;
}

.row > .m-3 {
  padding: 0;
}

.faq-tabs {
  .nav-tabs {
    border-bottom: 0;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-link,
    .nav-link:hover {
      border: unset;
    }

    .nav-link.active {
      color: #663399;
      background-color: unset;
      border: unset;
      font-weight: 600;
    }
  }
}

.verification_container_character {
  height: 45px;
  width: 40px;
}

.verification_container_character_inactive {
  background-color: #f0f0f0;
}

.nav-tabs {
    border-bottom: 1px solid $primary;
}

.nav-tabs .nav-item .nav-link.active {
    color: #665c70;
    background-color: #fff;
    border: 1px solid;
    border-color: #dee2e6 #dee2e6 #fff !important;
}

.dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    right: 5px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}